
export default class EnvironmentenvMapForm
{
    constructor(){

        // Setup

        var envMapWrapper = document.createElement('div');
        envMapWrapper.setAttribute("id", "envMapWrapper");
        envMapWrapper.classList.add("form-wrapper")
        document.body.appendChild(envMapWrapper);

        var envMapTitle = document.createElement("H2");
        envMapTitle.innerHTML = "Scegli ambientazione";
        envMapWrapper.appendChild(envMapTitle);

        var envMapText = document.createElement("p");
        envMapText.innerHTML = "Mappa ambientale utilizzata per creare effetti realistici d'illuminazione e riflessi.";
        envMapWrapper.appendChild(envMapText);

        var envMapForm = document.createElement("form");
        envMapForm.addEventListener('submit', event => {
            event.preventDefault();
            var checkedBoxes = document.querySelector('input:checked');
            window.dispatchEvent(
                new CustomEvent("onboardingEnvEnd", {
                  detail: checkedBoxes.value,
                })
            );
            document.body.removeChild(envMapWrapper);

        });

        // Create input 1
        var mapLabel1 = document.createElement("label");
        var mapInput1 = document.createElement("input");
        var mapTitle1 = document.createElement("h5");
        mapTitle1.innerHTML = "Sky"
        mapLabel1.appendChild(mapTitle1);
        mapInput1.type = "checkbox";
        mapInput1.name = "check[]";
        mapInput1.value = "sky";
        mapInput1.checked = true;
        mapInput1.classList.add("mapInput1")
        mapInput1.onclick = function () { onlyOne(this);};
        mapLabel1.appendChild(mapInput1);
        envMapForm.appendChild(mapLabel1);

        // Create input 2
        var mapLabel2 = document.createElement("label");
        var mapInput2 = document.createElement("input");
        var mapTitle2 = document.createElement("h5");
        mapTitle2.innerHTML = "Space"
        mapLabel2.appendChild(mapTitle2);
        mapInput2.type = "checkbox";
        mapInput2.name = "check[]";
        mapInput2.value = "space";
        mapInput2.classList.add("mapInput2")
        mapInput2.onclick = function () { onlyOne(this);};
        mapLabel2.appendChild(mapInput2);
        envMapForm.appendChild(mapLabel2);

        // Create input 3
        var mapLabel3 = document.createElement("label");
        var mapInput3 = document.createElement("input");
        var mapTitle3 = document.createElement("h5");
        mapTitle3.innerHTML = "Cyberpunk"
        mapLabel3.appendChild(mapTitle3);
        mapInput3.type = "checkbox";
        mapInput3.name = "check[]";
        mapInput3.value = "cyberpunk";
        mapInput3.classList.add("mapInput3")
        mapInput3.onclick = function () { onlyOne(this);};
        mapLabel3.appendChild(mapInput3);
        envMapForm.appendChild(mapLabel3);

        // Create input 4
        var mapLabel4 = document.createElement("label");
        var mapInput4 = document.createElement("input");
        var mapTitle4 = document.createElement("h5");
        mapTitle4.innerHTML = "Black"
        mapLabel4.appendChild(mapTitle4);
        mapInput4.type = "checkbox";
        mapInput4.name = "check[]";
        mapInput4.value = "black";
        mapInput4.classList.add("mapInput4")
        mapInput4.onclick = function () { onlyOne(this);};
        mapLabel4.appendChild(mapInput4);
        envMapForm.appendChild(mapLabel4);

        // Create button
        var mapButton = document.createElement("button");
        mapButton.type = "submit";
        mapButton.innerHTML = "Avanti";
        envMapForm.appendChild(mapButton);

        envMapWrapper.appendChild(envMapForm);

        function onlyOne(checkbox) {
            var checkboxes = document.getElementsByName('check[]')
            checkboxes.forEach((item) => {
                if (item !== checkbox) item.checked = false
            })
        }

        
    }

  
   
}