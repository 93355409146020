import EventEmitter from './EventEmitter'

export default class Sizes extends EventEmitter
{
    constructor(controller)
    {

        super()

        // Setup
        this.width = window.innerWidth
        this.height = (controller === 'button') ? vh(70) : window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        function vh(percent) {
            var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            return (percent * h) / 100;
        }

        // Resize event
        window.addEventListener('resize', () =>
        {
            this.width = window.innerWidth
            this.height = (controller === 'button') ? vh(70) : window.innerHeight
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)

            this.trigger('resize');
        })
    }
}