

export default class Controllers
{
    constructor(controller){


        // Setup
        const urlParams = new URLSearchParams(window.location.search);
        const controllerParam = urlParams.get('controller');
        this.controller = controller;
        const sessionToken = urlParams.get('ID');


        if(controllerParam){ this.controller = controllerParam }

        switch ( this.controller) {
            case "button":
                this.setControllerButton(sessionToken)
                break;
            
           /*  case "speech":
                this.setControllerSpeech()
                break; */

            case "audio":
                this.setControllerAudio(sessionToken)
                break;

                
            default:
                return
        }

    
    }

    setControllerButton(sessionToken)
    {
        const controllerButton = document.createElement('iframe');
        controllerButton.setAttribute("id", "controller");
        controllerButton.setAttribute('allow', 'microphone');
  
   
        controllerButton.src = '/controllers/tablet/index.html?ID=' + sessionToken + '&controller=button';
        controllerButton.style.position = 'absolute';
        controllerButton.style.width = "100%"
        controllerButton.style.height = '30%';
        controllerButton.style.bottom = '0';
        controllerButton.style.left = '0';
        controllerButton.style.opacity = '1';
        controllerButton.style.zIndex = "15";
  
        document.body.appendChild(controllerButton);
    }

    setControllerSpeech(sessionToken)
    {
        const controllerSpeech = document.createElement('iframe');
        controllerSpeech.setAttribute("id", "controller");
        controllerSpeech.setAttribute('allow', 'microphone');
  
        controllerSpeech.src = 'https://ekho-controller-audio.baasbox.dev/speech-recognition';
        controllerSpeech.style.position = 'absolute';
        controllerSpeech.style.width = "100vw"
        controllerSpeech.style.height = '100vh';
        controllerSpeech.style.bottom = '0';
        controllerSpeech.style.left = '0';
        controllerSpeech.style.opacity = '1';
        controllerSpeech.style.zIndex = "15";
  
        document.body.appendChild(controllerSpeech);
    }

    setControllerAudio(sessionToken)
    {
        const controllerSpeech = document.createElement('iframe');
        controllerSpeech.setAttribute("id", "controller");
        controllerSpeech.setAttribute('allow', 'microphone');
        controllerSpeech.setAttribute('data-type', 'audio');

  
        controllerSpeech.src = '/controllers/audio/index.html?ID=' + sessionToken;
        controllerSpeech.style.position = 'absolute';
        controllerSpeech.style.width = "100vw"
        controllerSpeech.style.height = '100vh';
        controllerSpeech.style.bottom = '0';
        controllerSpeech.style.left = '0';
        controllerSpeech.style.opacity = '1';
        controllerSpeech.style.zIndex = "15";
  
        document.body.appendChild(controllerSpeech);

    }

    

   
}